<template>
  <div class="grid ai-c jc-c h-100vh">
    <div class="box p-30 flex fd-c jc-c ai-c ggap-10">
      <h1 class="title">Ошибка 404</h1>
      <div>Такой страницы не существует!</div>
      <div class="grid gtc-2 ggap-30 mt-20">
        <button class="btn primary-light jc-c" @click.prevent="$router.back()">Назад</button>
        <RouterLink to="/" class="btn primary jc-c">На главную</RouterLink>
      </div>
    </div>
  </div>
</template>
